import React, { useEffect } from "react";
import { navigate } from "gatsby";

export default function NotFoundPage() {
  useEffect(() => {
    console.log('entra 404')
    navigate("/"); // redirecting to home page
  }, []);

  return (
    <div>
      
    </div>
  );
}